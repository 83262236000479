import React from "react"
import { graphql } from "gatsby"
import { Global } from "@emotion/core"

import Header from "../../components/header"
import Layout from "../../layouts"
import StarterLibrary from "./starter-library/index"

const StarterPage = ({ data: { allWpStarter }, location }) => {
  return (
    <Layout>
      <Global
        styles={{
          body: {
            overflow: `visible`,
          },
          a: {
            textDecoration: `none`,
          },
        }}
      />
      <Header isFullWidth />
      <main id={`reach-skip-nav`}>
        <StarterLibrary data={allWpStarter} location={location} />
      </main>
    </Layout>
  )
}

export default StarterPage

export const pageQuery = graphql`
  query StarterPageQuery {
    allWpStarter(
      filter: {
        starterFields: {
          screenshot: { localFile: { childImageSharp: { id: { ne: null } } } }
        }
      }
    ) {
      nodes {
        ...StarterDetails
      }
    }
  }
`
