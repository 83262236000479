/** @jsx jsx */
import { jsx } from "theme-ui"
import { debounce } from "lodash-es"
import { Component } from "react"
import PropTypes from "prop-types"
import {
  MdArrowDownward,
  MdArrowForward as ArrowForwardIcon,
} from "react-icons/md"
import { Button, AnchorButton } from "gatsby-interface"
import { mediaQueries } from "gatsby-design-tokens/dist/theme-gatsbyjs-org"
import { visuallyHiddenCss } from "../../../components/shared/styles/a11y"

import LHSFilter from "./lhs-filter"
import StarterList from "./starter-list"
import { themedInput } from "../../../utils/styles/org"
import { loadMoreButton } from "../../shared/styles"
import {
  SidebarHeader,
  SidebarBody,
  SidebarContainer,
  ContentHeader,
  ContentTitle,
  ContentContainer,
} from "../../shared/sidebar"
import ResetFilters from "../../shared/reset-filters"
import FooterLinks from "../../../components/shared/footer-links"
import SearchIcon from "../../../components/search-icon"
import { HeaderHeight } from "../../../components/shared/constants/layout"

export default class FilteredStarterLibrary extends Component {
  state = {
    sitesToShow: 12,
  }
  setFiltersCategory = filtersCategory => {
    this.props.setURLState({ c: Array.from(filtersCategory) })
  }
  setFiltersDependency = filtersDependency =>
    this.props.setURLState({ d: Array.from(filtersDependency) })
  setFiltersVersion = filtersVersion =>
    this.props.setURLState({ v: Array.from(filtersVersion) })
  resetFilters = () => this.props.setURLState({ c: [], d: [], v: [], s: `` })
  showMoreSites = starters => {
    const showAll =
      this.state.sitesToShow + 15 > starters.length ? starters.length : false
    this.setState({
      sitesToShow: showAll ? showAll : this.state.sitesToShow + 15,
    })
  }
  onChangeUrlWithText = value => this.props.setURLState({ s: value })

  render() {
    const { data, urlState } = this.props
    const {
      setFiltersCategory,
      setFiltersDependency,
      setFiltersVersion,
      resetFilters,
    } = this
    const filtersCategory = new Set(
      Array.isArray(urlState.c) ? urlState.c : [urlState.c]
    )
    const filtersDependency = new Set(
      Array.isArray(urlState.d) ? urlState.d : [urlState.d]
    )
    const filtersVersion = new Set(
      Array.isArray(urlState.v) ? urlState.v : [urlState.v]
    )
    // https://stackoverflow.com/a/32001444/1106414
    const filters = new Set(
      [].concat(
        ...[filtersCategory, filtersDependency, filtersVersion].map(set =>
          Array.from(set)
        )
      )
    )

    // stopgap for missing gh data (#8763)
    let starterNodes = data.nodes.filter(starter => starter.githubMetaFields)

    if (urlState.s.length > 0) {
      starterNodes = starterNodes.filter(node =>
        JSON.stringify(node)
          .toLowerCase()
          .includes(urlState.s.toLowerCase())
      )
    }

    if (filtersCategory.size > 0) {
      starterNodes = filterByCategories(starterNodes, filtersCategory)
    }
    if (filtersDependency.size > 0) {
      starterNodes = filterByDependencies(starterNodes, filtersDependency)
    }

    if (filtersVersion.size > 0) {
      starterNodes = filterByVersions(starterNodes, filtersVersion)
    }

    return (
      <section className="showcase" css={{ display: `flex` }}>
        <SidebarContainer css={{ overflowY: `auto` }}>
          <SidebarHeader />
          <SidebarBody>
            <div sx={{ height: t => t.space[11] }}>
              {(filters.size > 0 || urlState.s.length > 0) && ( // search is a filter too https://gatsbyjs.slack.com/archives/CB4V648ET/p1529224551000008
                <ResetFilters onClick={resetFilters} />
              )}
            </div>
            <LHSFilter
              fixed={150}
              heading="Gatsby Version"
              data={Array.from(
                count(
                  starterNodes.map(node =>
                    node.githubMetaFields.gatsbyMajorVersion.map(str => str[1])
                  )
                )
              )}
              filters={filtersVersion}
              setFilters={setFiltersVersion}
            />
            <LHSFilter
              heading="Categories"
              data={Array.from(
                count(
                  starterNodes.map(starter =>
                    starter.starterTags.nodes.map(tag => tag.name)
                  )
                )
              )}
              filters={filtersCategory}
              setFilters={setFiltersCategory}
              sortRecent={urlState.sort === `recent`}
            />
            <LHSFilter
              heading="Gatsby Dependencies"
              data={Array.from(
                count(
                  starterNodes.map(starter =>
                    starter.githubMetaFields.gatsbyDependencies.map(
                      str => str[0]
                    )
                  )
                )
              )}
              filters={filtersDependency}
              setFilters={setFiltersDependency}
              sortRecent={urlState.sort === `recent`}
            />
          </SidebarBody>
        </SidebarContainer>
        <ContentContainer>
          <ContentHeader
            cssOverrides={{
              height: `6rem`,
              pt: 7,
              top: `-1px`,
              [mediaQueries.sm]: {
                height: HeaderHeight,
                pt: 0,
              },
            }}
          >
            <ContentTitle
              search={urlState.s}
              filters={filters}
              label="Gatsby Starter"
              items={starterNodes}
              nodes={starterNodes}
              what="size"
            />
            <div
              sx={{
                display: `flex`,
                justifyContent: `space-between`,
                mb: 3,
                width: `100%`,
                [mediaQueries.sm]: {
                  justifyContent: `flex-end`,
                  mb: 0,
                  width: `50%`,
                },
              }}
            >
              <DebounceInput
                type="text"
                sx={{
                  ...themedInput,
                  pl: 8,
                }}
                value={urlState.s}
                onChange={this.onChangeUrlWithText}
                placeholder="Search starters"
                aria-label="Search starters"
              />
              <AnchorButton
                href="https://www.gatsbyjs.com/contributing/community-contributions/"
                target="_blank"
                rel="noopener noreferrer"
                size="M"
                rightIcon={<ArrowForwardIcon />}
                sx={{ ml: 4, height: `100%` }}
                variant="SECONDARY"
              >
                Submit a Starter
              </AnchorButton>
            </div>
          </ContentHeader>
          <StarterList
            urlState={urlState}
            sortRecent={urlState.sort === `recent`}
            starters={starterNodes}
            count={this.state.sitesToShow}
          />
          {this.state.sitesToShow < starterNodes.length && (
            <Button
              size="L"
              sx={loadMoreButton}
              onClick={() => this.showMoreSites(starterNodes)}
              rightIcon={<MdArrowDownward />}
            >
              Load More
            </Button>
          )}
          <FooterLinks />
        </ContentContainer>
      </section>
    )
  }
}

// utility functions

function count(arrays) {
  const counts = new Map()

  for (const categories of arrays) {
    if (!categories) continue

    for (const category of categories) {
      if (!counts.has(category)) {
        counts.set(category, 0)
      }

      counts.set(category, counts.get(category) + 1)
    }
  }

  return counts
}

function filterByCategories(nodes, categories) {
  return nodes.filter(node =>
    isSuperset(
      node.starterTags.nodes.map(tag => tag.name),
      categories
    )
  )
}

function filterByDependencies(nodes, categories) {
  return nodes.filter(({ githubMetaFields }) =>
    isSuperset(
      githubMetaFields.gatsbyDependencies.map(c => c[0]),
      categories
    )
  )
}

function filterByVersions(nodes, versions) {
  return nodes.filter(({ githubMetaFields }) =>
    isSuperset(
      githubMetaFields.gatsbyMajorVersion.map(c => c[1]),
      versions
    )
  )
}

function isSuperset(set, subset) {
  for (const elem of subset) {
    if (!set.includes(elem)) {
      return false
    }
  }
  return true
}

class DebounceInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    delay: PropTypes.number,
  }

  static defaultProps = {
    value: ``,
    delay: 250,
  }

  state = {
    inputValue: ``,
  }

  componentDidMount() {
    this.setInputValue(this.props.value)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value)
      this.setInputValue(this.props.value)
  }

  setInputValue = (value = ``) => {
    this.setState({ inputValue: value })
  }

  onChangeInputText = e => {
    this.setInputValue(e.target.value)
    e.persist()
    this.debounceOnChange()
  }

  onChangeValue = () => {
    this.props.onChange(this.state.inputValue)
  }

  debounceOnChange = debounce(this.onChangeValue, this.props.delay)

  render() {
    const { inputValue } = this.state
    return (
      <label css={{ position: `relative` }}>
        <span sx={visuallyHiddenCss}>Search starters</span>
        <input
          {...this.props}
          value={inputValue}
          onChange={this.onChangeInputText}
        />
        <SearchIcon />
      </label>
    )
  }
}
